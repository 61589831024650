@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Find Your System Section
 * -----------------------------------------------------------------------------
 * Base styles for the Find Your System Section component.
 *
 */
.findYourSystem {
  --constrained-offset: var(--margin-mobile);
  --slider-spacing: var(--spacing-xs);
  --itemview-transform: translateX(var(--constrained-offset));

  height: auto;
  width: 100%;
  position: relative;

  &__header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    justify-content: space-between;
    margin-block-end: var(--spacing-m);
    margin-block-start: 0;
    margin-inline-end: auto;
    margin-inline-start: auto;
    white-space: nowrap;
  }

  &__title:global(.is-loading) {
    @include loading();
    height: 26px;
    width: 200px;
    overflow: hidden;
  }

  &__controls {
    display: flex;
    gap: var(--spacing-l);
    justify-content: flex-start;
    height: 20px;
    overflow-x: auto;
    margin-inline-start: calc(-1 * var(--margin-mobile));
    padding-inline-end: var(--margin-mobile);
    padding-inline-start: var(--margin-mobile);
    width: calc(100% + 2 * var(--margin-mobile));
  }

  &__control {
    // Added this so underline is visible on desktop.
    padding-block-end: var(--spacing-xs);

    &:global(:hover:not(.isActive)) {
      text-decoration: underline;
    }

    &:global(:not(button.isActive)) {
      color: var(--color-mid-grey);
      text-decoration: none;
    }

    &:global(.is-loading) {
      @include loading();
      height: 18px;
      width: 100px;
      overflow: hidden;
    }
  }

  &__listing {
    display: flex;
    gap: 5px;
    overflow: hidden;
    width: fit-content;

    &:global(.is-loading) {
      transform: var(--itemview-transform);
    }
  }

  &__carousel {
    [data-slider-slide]:last-child [data-slider-itemview] {
      padding-right: var(--constrained-offset);
    }
  }

  &__card {
    width: 340px;

    &:global(.is-loading) {
      @include loading();
      @include aspect-ratio($force: true);
      // 340 / 330 aspect ratio
      --ratio-percent: calc(330 / 340 * 100%);
    }
  }

  &__hideCarousel {
    display: none;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @include mq($until: m) {
    &__introCard {
      display: none;
    }
  }

  @include mq($until: l) {
    &__wrapper {
      margin-block: var(--spacing-xl);
    }
  }

  @include mq($from: l) {
    $viewport-width: calc(100vw - var(--scrollbar-width, 0));
    --constrained-offset: calc(
      max(var(--max-content-width), #{$viewport-width}) / 2 -
        (var(--max-content-width) - var(--margin-desktop) * 2) / 2
    );
    --slider-spacing: var(--spacing-3xl);

    &__wrapper {
      margin-block-end: var(--spacing-5xl);
      margin-block-start: var(--spacing-5xl);
    }

    &__title:global(.is-loading) {
      height: 38px;
    }

    &__controls {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 25px;
    }

    &__carousel {
      [data-previous-button] {
        left: var(--constrained-offset);
      }

      [data-next-button] {
        right: var(--constrained-offset);
      }
    }

    &__card {
      width: 330px;

      &:global(.is-loading) {
        @include aspect-ratio($force: true);
        // 330 / 350 aspect ratio
        --ratio-percent: calc(350 / 330 * 100%);
      }
    }
  }

  @include mq($from: xl) {
    &__header {
      align-items: center;
      flex-direction: row;
      column-gap: var(--spacing-7xl);
      margin-block-end: var(--spacing-l);
      margin-block-start: 0;
    }

    &__controls {
      gap: var(--spacing-xl);
      justify-content: end;
    }

    [data-previous-button],
    [data-next-button] {
      display: none;
    }
  }
}
