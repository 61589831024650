@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Intro Card Component.
 * -----------------------------------------------------------------------------
 * Base styles for the Intro Card Component.
 *
 */
.introCard {
  // 340 / 330 aspect ratio
  --ratio-percent: calc(330 / 340 * 100%);
  color: var(--card-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  white-space: initial;

  &:global(.is-loading) {
    @include loading();
    width: 340px;
  }

  &__imageWrapper {
    @include aspect-ratio($force: true);
    height: 100%;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: auto;
  }

  &__title {
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin-block-end: var(--spacing-2xs);
    margin-block-start: 0;
    max-width: 224px;
  }

  &__cta {
    text-decoration: underline;
    text-underline-offset: var(--spacing-2xs);
  }

  &__content {
    position: absolute;
    top: var(--spacing-m);
    left: var(--spacing-l);
  }

  @include mq($from: l) {
    // 330 / 350 aspect ratio
    --ratio-percent: calc(350 / 330 * 100%);
    width: 330px;
  }

  @include mq($from: xl) {
    &__cta {
      display: none;
    }
  }
}
