@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: System Card Component.
 * -----------------------------------------------------------------------------
 * Base styles for the System Card Component.
 *
 */
.systemCard {
  $parent: &;
  background-color: var(--card-background-color);
  color: var(--card-color);
  display: flex;
  flex-direction: column;
  white-space: initial;
  height: 100%;
  justify-content: space-between;

  &:global(.is-loading) {
    @include loading();
    width: 340px;
    @include aspect-ratio($force: true);
    // 340 / 330 aspect ratio
    --ratio-percent: calc(330 / 340 * 100%);
  }

  &__details {
    display: grid;
    gap: var(--spacing-xs);
    grid-auto-flow: column;
    grid-template-columns: 104px 1fr;
    margin-bottom: var(--spacing-l);
    padding: var(--spacing-m) var(--spacing-m) var(--spacing-2xs);
  }

  &__imageWrapper {
    @include aspect-ratio($force: true);
    // 104 / 134 aspect ratio
    --ratio-percent: calc(134 / 104 * 100%);
    position: relative;
  }

  &__image {
    height: auto;
  }

  &__title {
    margin: var(--spacing-s) 0 var(--spacing-3xs);
  }

  &__icons {
    display: grid;
    gap: var(--spacing-4xl);
    grid-auto-flow: column;
    justify-content: center;
    margin-bottom: var(--spacing-xl);
  }

  &__icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    text-align: center;
    width: 40px;
  }

  &__iconTitle {
    min-width: 57px;
  }

  a#{$parent}__cta {
    margin: 0 var(--spacing-xl) var(--spacing-xl);
    width: fit-content;
  }

  @include mq($from: l) {
    width: 330px;

    &:global(.is-loading) {
      @include aspect-ratio($force: true);
      // 330 / 350 aspect ratio
      --ratio-percent: calc(350 / 330 * 100%);
    }
  }
}
